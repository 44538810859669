import { PrimaryButton } from 'components/common/buttons'
import { PublicStyling } from 'components/common/public-styling'
import { ANALYTICS_EVENTS } from 'core/constants'
import { t } from 'i18n'
import Marty from 'marty'
import PropTypes from 'prop-types'
import Radium from 'radium'
import React from 'react'
import PublicTeamsState from 'state/public-teams'
import Style from 'style'
import containerUtils from 'utilities/containers'
import { resolve } from 'utilities/deprecated-named-routes'
import { getSubdomain } from 'utilities/generic'
import { getOrigin } from 'utilities/http'
import { UserSignupForm } from './signup-form'
import { Helmet } from 'react-helmet'

const LOGIN_PATH = '/accounts/login/'

const styles = {
  ssoButton: {
    textAlign: 'center',
  },
  headerText: {
    maxWidth: 600,
    marginBottom: 30,
    fontWeight: 300,
    display: 'flex',
    justifyContent: 'center',
    [Style.vars.media.mobile]: {
      margin: '20px 0',
    },
  },
  headerTextInner: {
    fontSize: 30,
    maxWidth: 450,
    textAlign: 'center',
    lineHeight: 1,
  },
  signupFormContainer: {
    maxWidth: 600,
    borderRadius: 5,
    margin: 'auto',
  },
  companyLogo: {
    height: 120,
    width: 200,
    margin: '0 auto 0 auto',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    [Style.vars.media.mobile]: {
      margin: '2rem auto 0 auto',
    },
    [Style.vars.media.xSmall]: {
      margin: '1.5rem auto 0 auto',
    },
  },
}

@Radium
export class UserSignupPageInner extends React.Component {
  static contextTypes = {
    currentUser: PropTypes.object,
    router: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  }

  componentDidMount() {
    this.redirectIfNecessary()
  }

  componentDidUpdate() {
    this.redirectIfNecessary()
  }

  getLoginURL() {
    const parts = location.hostname.split('.')
    // if parts.length === 1, there is no subdomain.
    if (parts.length === 1) return getOrigin() + LOGIN_PATH
    if (parts[0] !== 'myagi' && parts[0] !== 'staging-web') parts.shift()
    let subdomain = getSubdomain()
    subdomain = subdomain ? `${subdomain}.` : ''
    const upperLevelDomain = parts.join('.')
    const port = location.port ? `:${location.port}` : ''
    const loginURL = `${window.location.protocol}//${subdomain}${upperLevelDomain}${port}${LOGIN_PATH}`
    return loginURL
  }

  redirectIfNecessary() {
    if (this.context.currentUser) {
      if (!this.context.currentUser.company) {
        this.context.router.push(resolve('company-signup'))
      } else {
        this.context.router.push(resolve('home'))
      }
    }
  }

  redirectToSSOURL = () => {
    window.location.href = this.props.subdomainCompany.force_sso_via_url
  }

  render() {
    const companyLogo = this.props.subdomainCompany && this.props.subdomainCompany.company_logo
    const loginURL = this.getLoginURL()

    let message = this.props.subdomainCompany
      ? t('sign_up_to_join_your_companyname', {
          companyName: this.props.subdomainCompany.name,
        })
      : this.props.linkCompany
      ? t('sign_up_to_start_viewing_training', {
          companyName: this.props.linkCompany.name,
        })
      : `${t('get_your_free_myagi_account')}.`

    const forceSSOViaURL =
      this.props.subdomainCompany && this.props.subdomainCompany.force_sso_via_url

    if (forceSSOViaURL) {
      message = t('sso_login_message')
    }
    return (
      <div>
        <Helmet defer={false} titleTemplate="%s | Myagi">
          <meta name="robots" content="noindex" />
        </Helmet>
        {companyLogo && (
          <div
            style={{
              ...styles.companyLogo,
              backgroundImage: `url(${companyLogo})`,
            }}
          />
        )}

        <div style={styles.signupFormContainer}>
          <div style={styles.headerText}>
            <div style={styles.headerTextInner}>{message}</div>
          </div>
          {forceSSOViaURL ? (
            <div style={styles.ssoButton}>
              <PrimaryButton style={styles.btn} onClick={this.redirectToSSOURL}>
                {t('access_myagi')}
              </PrimaryButton>
            </div>
          ) : (
            <UserSignupForm {...this.props} />
          )}
        </div>
      </div>
    )
  }
}

export const UserSignupPage = Marty.createContainer(UserSignupPageInner, {
  listenTo: [PublicTeamsState.Store],
  fetch: {
    teams() {
      if (!this.props.subdomainCompany) {
        return null
      }
      return PublicTeamsState.Store.getItems({
        limit: 0,
        ordering: 'name',
        fields: ['name', 'url', 'id', 'location_address'],
        company: this.props.subdomainCompany.id,
      })
    },
  },
  pending() {
    return containerUtils.defaultPending(this, UserSignupPageInner)
  },
  failed(errors) {
    return containerUtils.defaultFailed(this, UserSignupPageInner, errors)
  },
})

export class Page extends React.Component {
  static contextTypes = {
    location: PropTypes.object.isRequired,
  }

  componentDidMount() {
    analytics.track(ANALYTICS_EVENTS.BEGAN_USER_SIGNUP)
  }

  render() {
    return (
      <PublicStyling>
        <UserSignupPage />
      </PublicStyling>
    )
  }
}
