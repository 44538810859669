import React from 'react'
import Style from 'style'
import { ProgressRadial } from 'components/common/progress-radial'
import { t } from 'i18n'

export class PlanStats extends React.Component {
  static data = {
    plan: {
      required: true,
      fields: [
        'num_enrolled_users',
        'num_enrolled_users_in_own_company',
        'avg_like_rating',
        'avg_learn_rating',
        'owner.id',
        'owner.url',
      ],
    },
  }

  render() {
    const enrollCount = this.props.plan.num_enrolled_users_in_own_company
    const avgLikeRating = this.props.plan.avg_like_rating
    const likeProportion = avgLikeRating ? avgLikeRating / 5 : 1
    const avgLearnRating = this.props.plan.avg_learn_rating
    const learnProportion = avgLearnRating ? avgLearnRating / 5 : 1

    return (
      <div style={styles.planStatsContainer}>
        <div style={styles.radialContainer}>
          <ProgressRadial
            proportion={1}
            centerText={enrollCount.toString()}
            descText={t('enrolled_users')}
            style={styles.attemptsRadialBlack}
          />
        </div>
        <div style={styles.radialContainer}>
          <ProgressRadial
            proportion={likeProportion}
            centerText={avgLikeRating ? avgLikeRating.toFixed(2).toString() : 'N/A'}
            descText={t('like_rating')}
            style={avgLikeRating < 4 ? styles.attemptsRadialRed : styles.attemptsRadialGreen}
          />
        </div>
        <div style={styles.radialContainer}>
          <ProgressRadial
            proportion={learnProportion}
            centerText={avgLearnRating ? avgLearnRating.toFixed(2).toString() : 'N/A'}
            descText={t('learn_rating')}
            style={avgLearnRating < 4 ? styles.attemptsRadialRed : styles.attemptsRadialGreen}
          />
        </div>
      </div>
    )
  }
}

const styles = {
  planStatsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
  },
  attemptsRadialBlack: {
    progressRadialBar: {
      fill: Style.vars.deprecatedColors.textBlack,
    },
  },
  attemptsRadialRed: {
    progressRadialBar: {
      fill: Style.vars.deprecatedColors.errorRed,
    },
  },
  attemptsRadialGreen: {
    progressRadialBar: {
      fill: Style.vars.deprecatedColors.green,
    },
  },
  radialContainer: {
    maxWidth: 80,
    marginLeft: 18,
  },
}
