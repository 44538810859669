import { ANALYTICS_EVENTS } from 'core/constants'
import { getCurrentLocale, t, usingDefaultLocale } from 'i18n'
import _ from 'lodash'
import PropTypes from 'prop-types'
import Radium from 'radium'
import React from 'react'
import { View } from 'react-native'
import { Text } from 'shared-js/components/common/text'
import {
  FieldHeader,
  Formik,
  getPropsFromFormik,
  SubmitButton,
  Validation,
} from 'shared-js/components/common/form'
import * as Inputs from 'shared-js/components/common/inputs'
import PublicUsersState from 'state/public-users'
import Style from 'style'
import { getOrigin, qs } from 'utilities/http'
import { requestToJoinCompany } from '../../common'

const OTHER = 'other'
const ROLES = {
  store_staff_member: 'store_staff_member',
  store_manager: 'store_manager',
  area_manager: 'area_manager',
  training_manager: 'training_manager',
  head_office_staff_member: 'head_office_staff_member',
  other: 'other',
}
const HOW_DID_YOU_HEAR_ABOUT_US_OPTIONS = {
  from_employer: 'from_employer',
  from_colleague: 'from_colleague',
  from_subordinate: 'from_subordinate',
  from_brand_rep: 'from_brand_rep',
  an_industry_body: 'an_industry_body',
  an_industry_expert: 'an_industry_expert',
  contacted_by_myagi: 'contacted_by_myagi',
  word_of_mouth: 'word_of_mouth',
  search_engine: 'search_engine',
  other: 'other',
}

@Radium
export class UserSignupForm extends React.Component {
  static contextTypes = {
    router: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
    this.requiresRequest =
      props.subdomainCompany && !props.subdomainCompany.companysettings.public_access

    this.state = {
      otherSelected: false,
      errorMessage: null,
    }
  }

  onSubmitAndValid = async (values, actions) => {
    this.setState({ errorMessage: null })
    const invite_id = qs('sid')

    const company = !this.requiresRequest ? this.props.subdomainCompany : undefined
    const companyURL = company && !invite_id ? company.url : undefined
    const groups = []

    analytics.track(ANALYTICS_EVENTS.SUBMITTED_USER_SIGNUP, {
      email: values.email.toLowerCase(),
    })

    const details = {
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email.toLowerCase(),
      password: values.password,
      invite_id,
      subscribe: !!values.subscribe,
      groups,
      // This will be set by navigator locale or querystring
      locale: getCurrentLocale(),
      company: companyURL,
      self_selected_role: values.selfSelectedRole,
      how_did_you_hear_about_us_selection:
        values.howDidYouHearAboutUsOther || values.howDidYouHearAboutUs,
    }
    const teamUrl = values.teamId ? this.getTeamUrl(values.teamId) : ''
    if (teamUrl && !this.requiresRequest) details.learner_group = teamUrl

    try {
      const res = await PublicUsersState.ActionCreators.doListAction('create_and_login', details)

      if (invite_id || company) {
        window.location.href = `${getOrigin()}/views/home/`
        return
      }

      if (this.props.subdomainCompany && this.requiresRequest) {
        const requestData = {
          company: this.props.subdomainCompany.url,
          userId: res.body.id,
          team: teamUrl,
        }

        if (this.sharelinkUsed()) {
          requestData.sharelink_name = this.sharelinkUsed()
        }

        requestToJoinCompany(requestData, this.props.subdomainCompany, this.context.router)
        return
      }

      const link = this.context.location.state
        ? this.context.location.state.link
        : window.location.search

      this.context.router.push({
        pathname: `/signup/company/${link}`,
        state: { userID: res.body.id, link },
      })
    } catch (err) {
      console.error('Error creating user', err)
      const errorCode = err.response && err.response.status ? err.response.status : 403

      let errorMessage
      if (errorCode === 404) errorMessage = t('invitation_link_incorrect')
      else errorMessage = t('error_creating_account')

      this.setState({ errorMessage })
      actions.setSubmitting(false)
    }
  }

  sharelinkUsed = () => {
    if (this.context.location.state && this.context.location.state.link) {
      return this.context.location.state.link
    }
    return false
  }

  getOptions = (options) =>
    _.map(options, (value, key) => ({
      value: key,
      label: t(value),
    }))

  getTeamOptions = (teams) =>
    teams.map((team) => ({
      value: team.id,
      label: team.location_address ? `${team.name} (${team.location_address})` : team.name,
    }))

  getTeamUrl = (id) => {
    if (!this.props.teams) return ''
    const selectedTeam = this.props.teams.find((team) => team.id == id)
    return selectedTeam ? selectedTeam.url : ''
  }

  render() {
    const invite_id = qs('sid')
    const isStandardSignupPage = !this.props.subdomainCompany && !invite_id && usingDefaultLocale()
    const initialValues = {}

    const userEmail = qs('ueml')
    initialValues.prefillEmail = userEmail ? decodeURIComponent(userEmail).replace(' ', '+') : ''
    if (
      !initialValues.prefillEmail &&
      this.context &&
      this.context.location &&
      this.context.location.state
    )
      initialValues.prefillEmail = this.context.location.state.email

    const roleOptions = this.getOptions(ROLES)
    const howDidYouHearOptions = this.getOptions(HOW_DID_YOU_HEAR_ABOUT_US_OPTIONS)
    const teamOptions = this.props.teams ? this.getTeamOptions(this.props.teams) : []

    const passwordFieldHeader = !isStandardSignupPage ? t('create_password') : t('password')

    const teamId = Number(qs('team'))
    if (teamId) initialValues.teamId = teamId

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={Validation.createSchema({
          firstName: Validation.text({ required: true }),
          lastName: Validation.text({ required: true }),
          email: Validation.email({ required: true, unique: true }),
          password: Validation.text({ required: true, minLength: 8 }),
          teamId: Validation.number({
            required: !!this.props.subdomainCompany && !invite_id,
          }),
          selfSelectedRole: Validation.text({ required: isStandardSignupPage }),
          howDidYouHearAboutUs: Validation.text(),
          subscription: Validation.boolean(),
        })}
        onSubmit={this.onSubmitAndValid}
      >
        {(innerProps) => (
          <View style={styles.signupForm}>
            <FieldHeader>{t('your_name')}</FieldHeader>
            <Inputs.TextInput
              testID="nameInput"
              placeholder={t('first_name')}
              style={styles.input}
              errorMessageStyle={styles.errorMsg}
              placeholderTextColor={Style.vars.deprecatedColors.grey}
              autoCorrect={false}
              {...getPropsFromFormik(innerProps, 'firstName')}
            />
            <Inputs.TextInput
              testID="nameInput"
              placeholder={t('last_name')}
              style={styles.input}
              errorMessageStyle={styles.errorMsg}
              placeholderTextColor={Style.vars.deprecatedColors.grey}
              autoCorrect={false}
              {...getPropsFromFormik(innerProps, 'lastName')}
            />

            <FieldHeader>{t('your_email')}</FieldHeader>
            <Inputs.EmailInput
              testID="emailInput"
              style={styles.input}
              placeholder={null}
              placeholderTextColor={Style.vars.deprecatedColors.grey}
              errorMessageStyle={styles.errorMsg}
              {...getPropsFromFormik(innerProps, 'email')}
            />

            <FieldHeader>{passwordFieldHeader}</FieldHeader>
            <Inputs.PasswordInput
              testID="passwordInput"
              placeholderColor="faded"
              style={styles.input}
              placeholder={null}
              placeholderTextColor={Style.vars.deprecatedColors.grey}
              errorMessageStyle={styles.errorMsg}
              {...getPropsFromFormik(innerProps, 'password')}
            />

            {this.props.subdomainCompany && !invite_id && (
              <View>
                <FieldHeader>{t('select_a_team')}</FieldHeader>
                <Inputs.DropdownInput
                  style={styles.input}
                  options={teamOptions}
                  placeholder={`${t('please_choose')}...`}
                  {...getPropsFromFormik(innerProps, 'teamId')}
                />
              </View>
            )}

            {isStandardSignupPage && (
              <View>
                <View>
                  <FieldHeader>{t('your_role_at_your_company')}</FieldHeader>
                  <Inputs.DropdownInput
                    style={styles.input}
                    options={roleOptions}
                    placeholder={`${t('please_choose')}...`}
                    {...getPropsFromFormik(innerProps, 'selfSelectedRole')}
                  />
                </View>
                <View>
                  <FieldHeader>{t('how_did_you_hear_about_myagi')}</FieldHeader>
                  <Inputs.DropdownInput
                    style={styles.input}
                    options={howDidYouHearOptions}
                    placeholder={`${t('please_choose')}...`}
                    {...getPropsFromFormik(innerProps, 'howDidYouHearAboutUs')}
                    onChange={(value) => {
                      innerProps.setFieldValue('howDidYouHearAboutUs', value)
                      this.setState({ otherSelected: value === OTHER })
                    }}
                  />
                  {this.state.otherSelected && (
                    <Inputs.TextInput
                      ref={(el) => {
                        this.howDidYouHearAboutUsOtherInput = el
                      }}
                      testID="howDidYouHearAboutUsOtherInput"
                      errorMessageStyle={styles.errorMsg}
                      placeholder={t('tell_us_your_reason')}
                      placeholderTextColor={Style.vars.deprecatedColors.grey}
                      autoCorrect={false}
                      autoFocus
                      {...getPropsFromFormik(innerProps, 'howDidYouHearAboutUsOther')}
                    />
                  )}
                </View>
              </View>
            )}

            <View style={styles.checkboxContainer}>
              <Inputs.CheckboxInput
                style={styles.checkboxLabel}
                testID="subscribeInput"
                {...getPropsFromFormik(innerProps, 'subscribe')}
              />
              <Text style={styles.checkboxLabel}>{t('tick_this_box_to_receive_updates')}</Text>
            </View>

            {this.state.errorMessage && (
              <div style={styles.signupError}>{this.state.errorMessage}</div>
            )}

            <SubmitButton
              containerStyle={styles.signupButton}
              textStyle={styles.signupButtonText}
              nativeID="userSignupSubmitButton"
              testID="user-signup-submit-btn"
              loading={innerProps.isSubmitting}
              isValid={innerProps.isValid}
              onPress={innerProps.handleSubmit}
            >
              {t('continue')}
            </SubmitButton>
          </View>
        )}
      </Formik>
    )
  }
}

const styles = {
  errorMsg: {
    color: Style.vars.deprecatedColors.errorRed,
  },
  errorCode: {
    color: Style.vars.deprecatedColors.errorRed,
    textAlign: 'center',
  },
  checkboxLabel: {
    color: Style.vars.deprecatedColors.xxxDarkGrey,
    marginLeft: 10,
    fontSize: 14,
    fontWeight: '500',
  },
  input: {
    color: Style.vars.deprecatedColors.xxxDarkGrey,
  },
  signupButtonInvalid: {
    background: Style.vars.deprecatedColors.fadedPrimary,
    cursor: 'not-allowed',
  },
  signupButton: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  signupButtonText: {
    lineHeight: 2,
    color: Style.vars.deprecatedColors.white,
  },
  signupForm: {
    paddingLeft: 50,
    paddingRight: 50,
    marginTop: 10,
    marginBottom: 17,
  },
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 40,
    marginBottom: 30,
  },
  signupError: {
    color: Style.vars.deprecatedColors.errorRed,
    textAlign: 'center',
    marginBottom: '10px',
  },
}
