import {
  DeprecatedImageCropper,
  TextArea,
  TextInput,
  URLInput,
} from 'components/common/form-deprecated'
import { ButtonToggle, Form, SubmitButton } from 'components/common/form-deprecated/index'
import { MultiSelect } from 'components/common/form-deprecated/select'
import { Info } from 'components/common/info'
import { LoadingSpinner } from 'components/common/loading'
import { StaticModal } from 'components/common/modals/static'
import { TabsMixin } from 'components/common/tabs'
import { t } from 'i18n'
import _ from 'lodash'
import Marty from 'marty'
import PropTypes from 'prop-types'
import React from 'react'
import reactMixin from 'react-mixin'
import BadgesState from 'state/badges'
import ChannelsState from 'state/channels'
import TrainingPlansState from 'state/training-plans'
import containerUtils from 'utilities/containers'

const DISCOUNT_CODE = 'Single Discount Code'
const UNIQUE_CODES = 'Many Unique Codes'
const INIT_TAB = DISCOUNT_CODE

const styles = {
  formContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  imageContainer: {
    marginRight: 20,
  },
  badgeName: {
    'flex-grow': '1',
  },
  codes: {
    label: { fontWeight: 'normal' },
  },
  issuedCodes: {
    cursor: 'pointer',
  },
  info: {
    cursor: 'help',
    fontSize: 14,
    marginLeft: 5,
  },
}

@reactMixin.decorate(TabsMixin)
export class BadgeModalInner extends React.Component {
  static data = {
    trainingPlan: {
      required: true,
      fields: ['id', 'name', 'url', 'owner.id', 'owner.name'],
    },
  }

  static contextTypes = {
    displayTempPositiveMessage: PropTypes.func.isRequired,
    displayTempNegativeMessage: PropTypes.func.isRequired,
    router: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
  }

  state = {
    duplicateCodes: [],
    showIssuedCodes: false,
  }

  cleanCodes = (codes) => {
    codes = codes.split('\n')
    codes = _.trim(codes, ',')
    codes = codes.split(',')
    codes = codes.map((code) => _.trim(code, ' \n'))
    codes = _.filter(codes, (code) => code !== '')
    return codes
  }

  isCodesValid = (codes) => {
    const allCodesValid = true
    if (this.props.badge) {
      const dups = _.intersection(codes, this.props.badge.used_unique_codes)
      this.setState({ duplicateCodes: dups })
    }
    return allCodesValid
  }

  onModalContentChangeClick = (tab) => {
    this.onTabChange(tab)
  }

  showIssuedCodes = () => {
    this.setState((prevState) => ({ showIssuedCodes: !prevState.showIssuedCodes }))
  }

  getTabContentMap = () => {
    const initValues = this.props.badge || {}
    let issuedCodes
    if (this.props.badge) {
      // reverse issued codes to display them in order of distribution
      issuedCodes = (
        <ul>
          {this.props.badge.used_unique_codes.map((code) => <li key={code}>{code}</li>).reverse()}
        </ul>
      )
    }
    const discountCodeInput = (
      <div>
        <p>{t('redemption_code_info')}</p>
        <TextInput
          name="discount_code"
          initialValue={initValues.discount_code}
          placeholder={t('code')}
          initialIsAcceptable
          required={false}
        />
      </div>
    )
    let initUniqueCodes
    if (initValues.unique_codes) {
      initUniqueCodes = initValues.unique_codes.join(', ')
    }
    let usedCodeWarning = null
    if (this.state.duplicateCodes.length) {
      const dups = this.state.duplicateCodes.map((code) => <li key={code}>{code}</li>)
      usedCodeWarning = (
        <div>
          <p>{`${t('warning_badge_codes')}:`}</p>
          <ul>
            {this.state.duplicateCodes.map((code) => (
              <li key={code}>{code}</li>
            ))}
          </ul>
        </div>
      )
    }
    const issuedCodeText = `${this.state.showIssuedCodes ? 'Hide' : 'Show'} issued codes`
    const uniqueCodesInput = (
      <div>
        <p>
          {`${t('enter_unique_discount')}. `}
          <Info content={t('enter_unique_discount_info')} />
        </p>
        <TextArea
          initialValue={initUniqueCodes}
          name="unique_codes"
          required={false}
          isValid={this.isCodesValid}
          clean={this.cleanCodes}
          showError
          style={styles.codes}
          initialIsAcceptable
        />
        {usedCodeWarning}
        {this.props.badge && this.props.badge.used_unique_codes.length ? (
          <div>
            <u style={styles.issuedCodes} onClick={this.showIssuedCodes}>
              {issuedCodeText}
            </u>
            {' '}
            <Info content={t('codes_already_distributed')} />
          </div>
        ) : null}
        {this.state.showIssuedCodes ? issuedCodes : null}
      </div>
    )
    return {
      [DISCOUNT_CODE]: discountCodeInput,
      [UNIQUE_CODES]: uniqueCodesInput,
    }
  }

  onSubmitAndValid = (data) => {
    data.training_unit = `http://localhost:8000/api/v1/training_units/${
      this.props.params.channelId
    }/`
    data.discount_url = data.discount_url ? data.discount_url : ''
    // If badge_image is just a URL, do not send it back to the server.
    // Otherwise, will get a 400.
    if (this.props.badge && data.badge_image === this.props.badge.badge_image) {
      delete data.badge_image
    }

    if (!data.badge_image && this.props.badge.badge_image) {
      // if badge image hasn't changed, revert to original image
      data.badge_image = this.props.badge_image
    }

    const promise = this.props.params.badgeId
      ? BadgesState.ActionCreators.update(this.props.badge.id, data)
      : BadgesState.ActionCreators.create(data)

    promise.then(() => {
      this.context.displayTempPositiveMessage({
        heading: 'Badge saved',
      })
    })
    this.context.router.push(`/views/edit-content/channel/${this.props.params.channelId}/details/`)
  }

  render() {
    const initValues = this.props.badge ? this.props.badge : {}
    const initTrainingPlans = this.props.badge
      ? this.props.badge.new_training_plans.map((tp) => ({
        label: tp.name,
        value: tp.url,
      }))
      : []

    return (
      <StaticModal header={this.props.badge ? t('edit_badge') : t('create_badge')} {...this.props}>
        <Form onSubmitAndValid={this.onSubmitAndValid}>
          <div style={styles.formContainer}>
            <div style={styles.imageContainer}>
              <h3>{t('badge_image')}</h3>
              <DeprecatedImageCropper
                name="badge_image"
                aspectRatio={1}
                height={200}
                width={200}
                initialValue={initValues.badge_image}
                required
              />
            </div>
            <div style={styles.badgeName}>
              <h3>{t('badge_name')}</h3>
              <TextInput
                name="name"
                initialValue={initValues.name}
                initialIsAcceptable={!!initValues.name}
                required
              />
              <h3>
                {`${t('badge_description')}`}
                {' '}
                <small>{`(${t('optional')})`}</small>
              </h3>
              <TextInput
                name="description"
                initialValue={initValues.description}
                initialIsAcceptable={!!initValues.description}
                maxLength={200}
              />
            </div>
          </div>
          <h3>
            {t('select_plans')}
            <Info style={styles.info} content={t('these_plans_must_complete_earn_badge')} />
          </h3>
          <MultiSelect
            required
            name="training_plans"
            options={this.props.trainingPlans.map((tp) => ({
              label: tp.name,
              value: tp.url,
            }))}
            initialSelection={initTrainingPlans}
            placeholder={`${t('select_plans')}...`}
            noResultsText={`${t('no_plans_found')}.`}
            onChange={_.noop}
            multi
          />

          <h3>
            {`${t('reward_details')}`}
            {' '}
            <small>{`(${t('optional')})`}</small>
          </h3>
          <ButtonToggle
            leftLabel={DISCOUNT_CODE}
            rightLabel={UNIQUE_CODES}
            initialValue={INIT_TAB}
            onChange={this.onModalContentChangeClick}
          />
          <br />
          <br />
          {this.getTabContent()}
          <p>{t('url_given_to_user')}</p>
          <URLInput
            name="discount_url"
            initialValue={initValues.discount_url || 'URL'}
            initialIsAcceptable={initValues.discount_url}
            required={false}
          />
          <SubmitButton text={t('submit')} />
        </Form>
      </StaticModal>
    )
  }
}

export const BadgeModal = Marty.createContainer(BadgeModalInner, {
  contextTypes: {
    routeParams: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
  },

  listenTo: [ChannelsState.Store, TrainingPlansState.Store],

  fetch: {
    badge() {
      if (this.props.params.badgeId) {
        return BadgesState.Store.getItem(this.props.params.badgeId, {
          fields: [
            'name',
            'description',
            'badge_image',
            'badge_active',
            'discount_code',
            'discount_url',
            'unique_codes',
            'used_unique_codes',
            'new_training_plans.name',
            'new_training_plans.url',
            'new_training_plans.deactivated',
          ],
        })
      }
      return null
    },
    trainingPlans() {
      const query = {
        deactivated__isnull: true,
        training_units: this.props.params.channelId,
        fields: ['name', 'id'],
        limit: 0,
      }

      return TrainingPlansState.Store.getItems(query, {
        // Wait for channel updates before refetching data
        dependantOn: ChannelsState.Store,
      })
    },
  },
  pending() {
    return (
      <StaticModal header={t('edit_badge')} {...this.props}>
        <LoadingSpinner />
      </StaticModal>
    )
  },
  failed(errors) {
    return containerUtils.defaultFailed(this, BadgeModalInner, errors)
  },
})
